import React, { useEffect, useState } from 'react'

const useStoreLabel = (acceptsPayouts, isOpen) => {
    
    const [storeLabel, setStoreLabel] = useState('')

    useEffect(() => {
        getStoreLabel()
    }, [acceptsPayouts, isOpen])
    
    const getStoreLabel = () => {
        const labelArray = []
        if (acceptsPayouts) labelArray.push('Pay online')
        if (isOpen) labelArray.push('Open today')
        setStoreLabel(labelArray.join(' • '))
    }

    return storeLabel
}

export default useStoreLabel