import { _trackEvent } from "lib/mixpanel"
import Image from "next/image"
import Link from "next/link"
import React from "react"
import styled from "styled-components"

export default function CategoryItem({ category }) {
  const { id, image, title } = category
  return (
    <Container
      onClick={() => _trackEvent("Shopper Viewed Category", { name: title })}
    >
      <Link href={`/search?categoryId=${id}&title=${title}`}>
        <ImageContainer>
          <Image src={image} alt={title} objectFit="cover" layout="fill" />
        </ImageContainer>
      </Link>
      <Name>{title}</Name>
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
`
const Name = styled.p`
  font-size: 16px;
  font-family: "PhantomSans";
`
const ImageContainer = styled.div`
  width: 100%;
  height: 140px;
  object-fit: cover;
  margin-bottom: 14px;
  object-position: center;
  background-color: #eee;
  position: relative;

  @media (max-width: 550px) {
    height: 120px;
  }
`
