import React from 'react'
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import Link from 'next/link';
const OwlCarousel = dynamic(import('react-owl-carousel'),{ssr:false});

export default function FeaturedCategoriesCarousel({ featuredCategories }) {
    return (
        <section className="arrivals">
            <div className="full-width-container testimonial">
                <div className="container testimonial-container">
                    <div>
                    <OwlCarousel className='owl-theme' margin={20} nav
                        responsive={{
                            0:{
                                items:1,
                                autoplay: true,
                                autoplayHoverPause: true,
                                nav:true,
                                loop: true,
                                dots: false,
                                autoplayTimeout: 4000,
                                autoplaySpeed: 2000,
                                navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']
                            },
                        }}
                        >
                            {featuredCategories && featuredCategories.map((category, index) => (
                                <div key={index} className="carousel-item active">
                                    <div className="row testimonial-row">
                                        <div style={{width: '100%'}}>
                                            <Link href={`/search?categoryId=${category.id}&title=${category.title}`}>
                                                <Image style={{objectFit: 'cover', width: '100%', maxHeight: 400}} src={category.image} alt="category-image" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

const Image = styled.img`
    object-fit: cover;
    width: 100%;
    max-height: 400px;
`;