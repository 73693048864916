import React from 'react'
import styled from 'styled-components';
import CategoryItem from './category-item';
import dynamic from 'next/dynamic';
import { Section } from './stores-carousel';
import { CarouselContainer } from './reusables';
import Link from 'next/link';

const OwlCarousel = dynamic(import('react-owl-carousel'),{ssr:false});

export default function CategoriesCarousel({ data, title }) {
    return (
        <Section className="arrivals">
            <div className="full-width-container">
                <div className="container">
                    <CarouselContainer className="new">
                        <Link href="/search">
                            <CarouselTitle style={{cursor: 'pointer'}}>{title}</CarouselTitle>
                        </Link>
                        <OwlCarousel className='owl-theme' loop margin={10} nav
                        responsive={{
                            0:{
                                items:3,
                                nav:true,
                                loop: true,
                                navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']

                            },
                            600:{
                                items:5,
                                nav:true,
                                loop: true,
                                navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']

                            },
                            1000:{
                                items:7,
                                nav:true,
                                loop:true,
                                navText: ['<img src="/left.png" className="d-block" />', '<img src="/right.png" className="d-block" />']
                            }
                        }}
                        >
                            {data.map((item, index) => (
                                <CategoryItem key={index} category={item} />
                            ))}
                        </OwlCarousel>
                    </CarouselContainer>
                </div>
            </div>
        </Section>
    )
}

const CarouselTitle = styled.h3`
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 20px;
`;