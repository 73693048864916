import mixpanel from "mixpanel-browser"
import LOGGER from "./logger"
mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN)
const development = process.env.NODE_ENV === "development"
const FILE_NAME = "mixpanel.js"

export const _trackEvent = (event, params) => {
  if (development) {
    return
  }
  // LOGGER.Debug(FILE_NAME, "_trackEvent", { event, params })
  mixpanel.track(event, { ...params, source: "web" })
}

export const TRACKING_EVENTS = {
  STORE_VISITED: "Store Visited",
  PRODUCT_ADDED_TO_CART: "Shopper Product Added To Cart",
  TRACKING_PAGE_VIEWED: "Shopper Visited Order Tracking",
  BOOK_RESERVE_BUTTON_CLICKED: "Book Reserve Button Clicked",
  SUCCESSFUL_BOOK_RESERVE: "Book Reserved Successfully",
  CLICKED_GLOBAL_SEARCH_FROM_STORE_SEARCH:
    "Shopper Clicked Global Search From Store Search",
  CLICKED_ORDER_TRACKING_FROM_RECEIPT:
    "Shopper Clicked Order Tracking From Receipt",
  CART_VIEWED: "Shopper Visited Cart Page",
  CHECKOUT_STARTED: "User Started Checkout",
  DISCOUNT_CODE_ENTERED: "Shopper entered discount code",
  PRODUCT_VIEWED: "Shopper Product Viewed",
  PICKUP_METHOD_SELECTED: "Shopper Selected Pickup Method",
  PAYMENT_METHOD_SELECTED: "Shopper Selected Payment Method",
  CHECKOUT_CONFIRMATION_SELECTED: "Shopper Selected Checkout Confirmation",
  PAYMENT_PROCESSOR_SELECTED: "Shopper Directed to Payment Processor",
  CHECKOUT_COMPLETED: "Shopper Completed Checkout",
  CHECKOUT_FAILED: "Shopper Failed Checkout",
  CUTOFF_MESSAGE_DISPLAYED: "Shopper Cutoff Time Message Displayed",
}
