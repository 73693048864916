import { Flex } from "components/layout/shared"
import Spacer from "components/spacer"
import useStoreLabel from "custom-hooks/useStoreLabel"
import { getPublicUrl, sluggedName, toCurrency } from "lib/utils"
import Image from "next/image"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"
import { colors } from "styles/colors"

export default function SaleItem({ product }) {
  const router = useRouter()
  const {
    companyName,
    image,
    onSale,
    price,
    salePrice,
    title,
    companyAcceptsCash,
    companyAcceptsPayouts,
    id,
  } = product
  const storeLabel = useStoreLabel(companyAcceptsPayouts)
  return (
    <Container
      onClick={() =>
        router.push(`/store/${sluggedName(companyName)}?productId=${id}`)
      }
    >
      <ImageContainer>
        {image && (
          <Image
            src={getPublicUrl(image) ?? ""}
            alt={title}
            objectFit="cover"
            layout="fill"
          />
        )}
      </ImageContainer>
      <Flex direction="column">
        <Flex direction="row" align="flex-start" justify="space-between">
          <Name>{title}</Name>
          <Flex direction="row" align="center">
            {onSale ? <SalePrice>{toCurrency(salePrice)}</SalePrice> : null}
            <Price onSale={onSale ? true : undefined}>
              {toCurrency(price)}
            </Price>
          </Flex>
        </Flex>
        <Spacer size={3} />
        <CompanyName>{companyName}</CompanyName>
        <Description>{storeLabel}</Description>
      </Flex>
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`
const Name = styled.p`
  font-size: 22px;
  line-height: 1.1;
  padding-right: 20px;
  margin: 0;
`
const CompanyName = styled.p`
  font-size: 15px;
  margin-bottom: 2px;
`
const Price = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: ${({ onSale }) => (onSale ? "line-through" : "none")};
  font-family: "PhantomSans";
  margin: 0;
`
const SalePrice = styled.p`
  color: ${colors.sale};
  font-family: "PhantomSans";
  margin: 0 5px 0 0;
`
const Description = styled.p`
  margin: 0;
  font-size: 14px;
  font-family: "PhantomSans";
  line-height: 1.2;
  opacity: 0.8;
`
const ImageContainer = styled.div`
  height: 400px;
  width: 350px;
  margin-bottom: 20px;
  /* width: 100%; */
  object-fit: cover;
  object-position: center;
  background-color: #eee;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`
