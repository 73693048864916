import useStoreLabel from 'custom-hooks/useStoreLabel';
import { useStoreOpen } from 'lib/hooks';
import { getPublicUrl, getStoreOpeningAndClosing, getTodaysHours } from 'lib/utils';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react'
import styled from 'styled-components';

export default function StoreItem({ store, useHeader }) {
    const router = useRouter()
    const { acceptsCash, acceptsPayouts, featuredProducts, logo, headerImage, name, openingDays, storeSlug } = store;
    const todayHours = getTodaysHours(openingDays);
    const { isOpen } = useStoreOpen(openingDays, todayHours, getStoreOpeningAndClosing(todayHours.openingTimeObj, todayHours.closingTimeObj));
    const storeLabel = useStoreLabel(acceptsPayouts, isOpen)

    const coverPhoto = getPublicUrl((featuredProducts && featuredProducts.length) ? featuredProducts[Math.floor(Math.random() * featuredProducts.length)].image : logo)
   
   
    return (
        <Container onClick={() => router.push(`/store/${storeSlug}`)}>
                {useHeader ?
                    <HeaderImageContainer>
                        <Image
                            src={headerImage}
                            alt={name}
                            objectFit="cover"
                            layout="fill"
                        />
                    </HeaderImageContainer>
                    :
                    <ImageContainer>
                        <Image
                            src={coverPhoto}
                            alt={name}
                            objectFit="cover"
                            layout="fill"
                        />

                    </ImageContainer>
                }
            <div>
                <Name>{name}</Name>
                <Description>{storeLabel}</Description>
            </div>
        </Container>
    )
}

const Container = styled.div`
    cursor: pointer;
`;
const Name = styled.p`
    font-size: 18px;
    font-family: 'PhantomSans';
    margin-bottom: 2px;  
    font-weight: 500;
    line-height: 1.2;
    word-break: break-word;
`;
const ImageContainer = styled.div`
    width: 100%;
    height: 140px;
    object-fit: cover;
    margin-bottom: 14px;
    object-position: center;
    background-color: #eee;
    position: relative;
    
    @media (max-width: 550px) {
        height: 120px;
    }
`
const HeaderImageContainer = styled.div`
    height: 160px;
    margin-bottom: 14px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    background-color: #eee;
    position: relative;
`;
const Description = styled.p`
    margin: 0;
    font-size: 14px;
    font-family: 'PhantomSans';
   line-height: 1.2;
`;